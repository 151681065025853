<template>
    <el-scrollbar class="scrollbar">
        <el-tabs :tab-position="tabPosition" v-model="activeName">
            <el-tab-pane label="AI TOP 8" name="0" lazy>
                <!-- <Top8 @update:activeTab="handleTabChange" /> -->
                <Top8 v-for="website in websites" :key="website.id" :id="website.id" :title="website.title"
                    @update:activeTab="handleTabChange">
                </Top8>
            </el-tab-pane>
            <el-tab-pane :label=item.title :name="item.id" lazy v-for="(item, index) in websites" :key="index">
                <All :id="item.id" :rm="item.rm" />
            </el-tab-pane>
        </el-tabs>
    </el-scrollbar>
</template>

<script>
import axios from 'axios';

import Top8 from './Pc_Top8_chi.vue'
import All from './Pc_All.vue'

export default {
    data() {
        return {
            activeName: '0',
            websites: [],
            tabPosition: "top"
        }
    },
    components: {
        Top8,
        All
    },
    mounted() {
        this.fetch_for_websites_tags();
    },
    methods: {
        handleTabChange(tabName) {
            this.activeName = tabName;
        },
        async fetch_for_websites_tags() {
            try {
                const response = await axios.get('https://api.aieyes.com.cn/api/websites/tags');
                this.websites = response.data.map(item => ({
                    id: item.id,
                    title: item.tagtitle,
                    rm: item.remarks
                }));
            } catch (error) {
                console.error('Error fetching total articles:', error);
            }
        }
    }
}
</script>
<style>
.el-tabs--top {
    padding: 2vh;
}
</style>