<template>
	<canvas ref="canvas_l_1" style="transform-origin: 0px 0px 0px; content-visibility:
		visible; z-index: 10;opacity: 1;position: fixed;left: 0;top: 0; pointer-events: none;">
	</canvas>
</template>

<script>
	export default {
		name: 'canv-1',
		data() {
			return {

			};
		},
		mounted() {
			
			let flag = navigator.userAgent.match(
				/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
			)
			
			const canvas = this.$refs.canvas_l_1;
			canvas.width = window.innerWidth;
			canvas.height = window.innerHeight;
			const ctx = canvas.getContext('2d');
			const centerX = canvas.width;
			const centerY = 0;
			
			var radii = null;
			var base = null;
			if(!flag){
				radii = [310, 350, 420, 490, 530, 560, 600]; // 同心圆半径数组
				base = 53;
			} else {
				radii = [30, 50, 90, 150, 190, 200, 220]; // 同心圆半径数组
				base = 20;
			}
			const ballColors = ['#C47F88', '#915BC1', '#C47F81', '#00FFFF', '#FF00FF', '#FFFF00', '#00FF00'];
			const ballTexts = ['AI写作', 'AI编程', 'AI助手', 'AI伙伴', 'AI检索', 'AI总结', 'AI设计'];

			// 绘制同心圆
			radii.forEach(radius => {
				ctx.beginPath();
				ctx.arc(centerX, centerY, radius, 0, Math.PI * 2);
				ctx.strokeStyle = '#95C6C3';
				ctx.stroke();
			});

			// 绘制实心小球
			const drawBalls = (angle) => {
				ballColors.forEach((color, index) => {
					ctx.beginPath();
					ctx.fillStyle = color; // 设置小球的颜色
					const ballRadius = 60 + index * base; // 每个小球不同的半径，确保小球不会重叠
					const ballX = centerX + (radii[0] + ballRadius) * Math.cos(angle + (Math.PI * 2) /
						ballColors.length * index);
					const ballY = centerY + (radii[0] + ballRadius) * Math.sin(angle + (Math.PI * 2) /
						ballColors.length * index);
					ctx.arc(ballX, ballY, 10, 0, Math.PI * 2); // 假设所有小球的半径相同，如果不同可以更改为上面的球Radius
					ctx.fill();

					// 计算文字位置
					const textX = ballX + 13; // 文字距离小球的距离
					const textY = ballY + 5; // 调整文字的垂直位置

					ctx.fillStyle = '91BFBF'; // 文字颜色
					ctx.font = '18px Arial'; // 文字字体大小和字体
					const text = ballTexts[index]; // 获取对应的文字

					ctx.fillText(text, textX, textY);
				});
			};

			// 动画效果
			let angle = 0;
			const animate = () => {
				ctx.clearRect(0, 0, canvas.width, canvas.height); // 清除画布
				// 重新绘制同心圆
				radii.forEach(radius => {
					ctx.beginPath();
					ctx.arc(centerX, centerY, radius, 0, Math.PI * 2);
					ctx.strokeStyle = '#95C6C3';
					ctx.stroke();
				});
				// 绘制旋转的小球
				drawBalls(angle);
				angle += 0.01; // 改变角度
				requestAnimationFrame(animate); // 递归调用animate实现动画效果
			};

			animate(); // 开始动画
		}
	};
</script>