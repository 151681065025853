<template>
    <div class="container" v-loading="loading" element-loading-text="Loading...">
        <p class="title" @click="switchTab()">
            {{ title }}
            <el-tag type="primary">TOP 8</el-tag>
        </p>
        <hr class="separator" />
        <div class="websites">
            <div class="website" v-for="(item, index) of articles_websites_draw" :key="index"
                @click="navigateTo(item.aimUrl)">
                <img :src="item.image" alt="Website Image" class="website-image" />
                <div class="website-description">
                    <h2>{{ item.name }}</h2>
                    <p>{{ item.description }}</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import axios from 'axios';

export default {

    name: 'Pc_Top8_chi',
    props: {
        id: {
            type: Number,
            required: true,
        },
        title: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            articles_websites_draw: [],
            loadingInstance: null,
        }
    },
    mounted() {
        this.fetch_for_websites_Top8();
    },
    methods: {
        navigateTo(url) {
            window.open(url, '_blank');
        },
        switchTab() {
            this.$emit('update:activeTab', this.id);
        },
        async fetch_for_websites_Top8() {
            try {
                const response = await axios.get('https://api.aieyes.com.cn/api/websites/tag/top_8', {
                    params: { id: this.id },
                });
                this.articles_websites_draw = response.data.map(item => ({
                    name: item.title,
                    description: item.description,
                    aimUrl: item.aimUrl,
                    image: item.imageUrl
                }));
            } catch (error) {
                console.error('Error fetching total articles:', error);
            }
        }
    }
}
</script>

<style>
.custom-loading .el-loading-text {
    color: #e13aa3dc;
    font-size: 34px;
}
</style>

<style scoped>
.container {
    background-color: white;
    width: 85%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
    border-radius: 2vh;
    padding: 5px;
}

.title {
    margin-bottom: 5px;
    font-size: 18px;
    letter-spacing: 9px;
    font-weight: bold;
    text-align: left;
    margin-left: 40px;
    color: cornflowerblue;
    transition: box-shadow 0.4s ease, transform 0.4s ease;
    /* 设置过渡效果 */
    box-shadow: none;
    /* 初始没有阴影 */
    transform: translateX(0);
    /* 初始没有平移 */
}

.title:hover {
    transform: translateX(20px);
    /* 向上平移10px */
    cursor: pointer;
    color: chocolate;
}

.separator {
    border: 0;
    height: 1px;
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0));
    margin-bottom: 20px;
}

.websites {
    display: flex;
    flex-wrap: wrap;
    /* justify-content: flex-start;
    align-items: stretch; */
    gap: calc(1%);
    /* 设置水平和垂直间隔 */
}

.website {
    width: calc(24%);
    display: flex;
    margin-bottom: 5px;
    /* margin-right: auto; */
    transition: box-shadow 0.3s ease, transform 0.3s ease;
    /* 设置过渡效果 */
    box-shadow: none;
    /* 初始没有阴影 */
    transform: translateY(0);
    /* 初始没有平移 */
}

.website:hover {
    background-color: rgba(105, 105, 105, 0.1);
    border-radius: 8px;
    box-shadow: 10px 10px 15px rgba(0, 0, 0, 0.2);
    /* 添加右下角阴影 */
    transform: translateY(-10px);
    /* 向上平移10px */
    transform: translateX(2.5px);
    /* 向上平移10px */
    cursor: pointer;
    scale: 1.05;
}

.website:hover h2 {
    color: #db25a5bc;
    /* 将颜色设置为红色 */
}

.website:hover .website-description {
    white-space: normal;
}

.website-image {
    scale: 0.6;
    width: 70px;
    height: 70px;
    border-radius: 50%;
}

.website-description {
    word-break: break-word;
    overflow-wrap: break-word;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.website-description p {
    font-size: 12px;
    font-weight: bold;
}

h2 {
    margin: 0 0 1px 0;
}

p {
    margin: 0;
}
</style>