<script setup>
	import {
		onMounted
	} from "vue";

	const dpr = window.devicePixelRatio;

	function randomInnerPoint() {
		return {
			x: Math.random() * window.innerWidth,
			y: Math.random() * window.innerHeight,
		};
	}
	class Graphic {
		constructor() {
			//点的数量  
			let flag = navigator.userAgent.match(
				/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
			)
			if(!flag){
				this.graphicNum = 30;
			} else {
				this.graphicNum = 10;
			}
			this._canvas = document.querySelector("#canvas");
			this._ctx = this._canvas.getContext("2d");
			this._canvas.width = window.innerWidth * dpr;
			this._canvas.height = window.innerHeight * dpr;
			this._ctx.scale(dpr, dpr);

			this._points = new Array(this.graphicNum).fill(0).map(() => ({
				...randomInnerPoint(), //起点坐标  
				target: randomInnerPoint(), //终点坐标  
				speed: 1.2, // 控制速度  
			}));
		}

		// 绘制，属性为相距最远的路线  
		draw(maxDis = 140) {
			//绘制线条  
			this._points.forEach((point, index) => {
				const point1 = point;
				for (let i = index; i < this.graphicNum; i++) {
					const point2 = this._points[i % this.graphicNum];

					this._ctx.beginPath();
					this._ctx.moveTo(point1.x, point1.y);
					this._ctx.lineTo(point2.x, point2.y);
					const dis = Math.sqrt(
						Math.pow(point1.x - point2.x, 2) + Math.pow(point1.y - point2.y, 2)
					);
					//控制透明度  
					this._ctx.strokeStyle = `rgba(200,200,200,${1 - dis / maxDis})`;
					this._ctx.lineWidth = 1;
					this._ctx.stroke();
				}
			});

			// 绘制端点  
			this._points.forEach((point) => {
				this._ctx.beginPath();
				this._ctx.arc(point.x, point.y, 4, 0, 2 * Math.PI);

				this._ctx.fillStyle = `#8ce1ff`;
				this._ctx.fill();
			});
		}

		clear() {
			//清空画布  
			this._ctx.clearRect(0, 0, this._canvas.width, this._canvas.height);
		}

		update() {
			this._points.forEach(point => {
				// 计算距离  
				const dx = point.target.x - point.x;
				const dy = point.target.y - point.y;
				const distance = Math.sqrt(dx * dx + dy * dy);

				// 如果点到达目标位置，生成新的目标位置  
				if (distance < point.speed) {
					point.target = randomInnerPoint();
				} else {
					// 按照速度移动点的位置  
					point.x += (dx / distance) * point.speed;
					point.y += (dy / distance) * point.speed;
				}
			});
		}
	}

	function initBackGraphic() {
		const graphics = new Graphic();
		let animationFrameId = null;

		function animate() {
			graphics.clear();
			graphics.update();
			graphics.draw();
			// 递归调用下一帧  
			animationFrameId = requestAnimationFrame(animate);
		}
		console.log(animationFrameId);
		animate(); // 开始动画  
	}

	onMounted(() => {
		initBackGraphic()
	});
</script>

<template>
	<canvas id="canvas" ref="canvas_l_2" style="transform-origin: 0px 0px 0px; content-visibility:
		visible; z-index: 0;opacity: 1;position: fixed;left: 0;top: 0; pointer-events: none;">
	</canvas>
</template>