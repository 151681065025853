import { createApp } from 'vue';
import { createRouter, createWebHashHistory } from 'vue-router';

import App from './APP.vue';

import {
    ElAffix,
    ElMenu,
    ElMenuItem,
    ElTag,
    ElTooltip,
    ElTabs,
    ElTabPane,
    ElScrollbar,
    ElSkeleton,
    ElSkeletonItem
} from 'element-plus';

import 'element-plus/dist/index.css';

import Home from './components/App_Home_Pc.vue';

import Home_mob from './components/App_Home_Mob.vue';

import Home_main from './components/App_Home_Main.vue';

import Loading from './Loading.vue';


const repertory = () => import("./components/RepertoryPage.vue");

const app = createApp(App);

const routes = [
    {
        path: '/App_Home_Main',
        name: 'App_Home_Main',
        component: Home_main,
        children: [
            {
                path: '/App_Home_Pc',
                name: 'App_Home_Pc',
                component: Home,
            },
            {
                path: '/repertory',
                name: 'repertory',
                component: repertory,
            },
            {
                path: '/App_Home_Mob',
                name: 'App_Home_Mob',
                component: Home_mob,
            },
        ],
    },
    {
        path: '/Loading',
        name: 'Loading',
        component: Loading,
    },
    {
        path: '/',
        redirect: '/Loading'
    }
]

const router = createRouter({
    history: createWebHashHistory(),
    routes: routes
})

app.component(ElAffix.name, ElAffix);
app.component(ElMenu.name, ElMenu);
app.component(ElMenuItem.name, ElMenuItem);
app.component(ElTag.name, ElTag);
app.component(ElTooltip.name, ElTooltip);
app.component(ElTabs.name, ElTabs);
app.component(ElTabPane.name, ElTabPane);
app.component(ElScrollbar.name, ElScrollbar);
app.component(ElSkeleton.name, ElSkeleton);
app.component(ElSkeletonItem.name, ElSkeletonItem);

app.use(router);

app.mount('#app');