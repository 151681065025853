<template>
	<div class="main">
		<div :class="{ animated: isAnimated }">
			<img v-if="isAnimated" :src="imageE" />
		</div>
		<div :class="{ animated_2: isAnimated }">
			<img v-if="isAnimated" :src="imageE_2" />
		</div>
		<div class="container" v-if="!isAnimated">
			<div class="TITLE">
				<div class="TITLE-MAIN">AI123</div>
			</div>
			<div class="DES-MAIN">
				<hr class="divider">
				<p class="des">Let's go to the world of AI together</p>
			</div>
			<div class="START">
				<div class="START-BUTTON">
					<div class="home" style="z-index: 50;">
						<a @click="MoveToHome()">
							<span>START</span>
							<div class="image-container">
								<img src="./img/rocket_1.png" alt="Hover Image" class="hover-image" id="bsr_1">
							</div>
						</a>
						<span class="ok">START</span>
					</div>
				</div>
			</div>
			<div class="SOLAR">
			</div>
		</div>
	</div>
	<canv_1 v-if="!isAnimated" id="bsr_2" />
	<canv_2 v-if="!isAnimated" />
</template>

<script>
	import imageE from "./img/trans.png";
	import imageE_2 from "./img/rocket_1.png";

	import canv_1 from "./spchunk/canv_1.vue";
	import canv_2 from "./spchunk/canv_2.vue";

	// import SatelliteOrbit from './components/SatelliteOrbit.vue';

	export default {
		name: 'App_root',
		data() {
			return {
				imageE: imageE,
				imageE_2: imageE_2,
				isAnimated: false
			};
		},
		components: {
			canv_1,
			canv_2
		},
		methods: {
			MoveToHome() {
				this.isAnimated = true;
				setTimeout(() => {
					this.$router.push("/App_Home_Main");
				}, 2000);
			},
		},
	};
</script>

<style>
	html,
	body {
		overflow-x: hidden;
		overflow-y: hidden;
		height: 100vh;
		width: 100vw;
		margin: 0;
		padding: 0;
	}

	.animated {
		animation: yourAnimation 2s;
		position: fixed;
		top: 0;
		right: 0;
		width: 120vw;
		height: 200vh;
		overflow: hidden;
	}

	.animated img {
		width: 120vw;
		height: 200vh;
	}

	.animated_2 {
		animation: yourAnimation_2 2s;
		position: fixed;
		top: 0;
		right: 0;
		width: 50vw;
		height: 70vh;
		overflow: hidden;
	}

	.animated_2 img {
		width: 50vw;
		height: 70vh;
	}

	.DES-MAIN .divider {
		border-bottom: 4px solid #bbffc3;
		margin: 0px 0;
		animation: growWidth_1 1s ease-in-out forwards;
	}

	.DES-MAIN .des {
		color: aliceblue;
		animation-delay: 1s;
		animation: slideIn_1 2s ease-out forwards;
		margin: 0%;
		padding: 0%;
	}

	@keyframes yourAnimation {
		from {
			top: -100vh;
			right: -30vw;
		}

		to {
			top: 0vh;
			right: 0vw;
		}
	}

	@keyframes yourAnimation_2 {
		from {
			top: 100vh;
			right: 90vw;
		}

		to {
			top: -70vh;
			right: -30vw;
		}
	}

	@keyframes slideIn_1 {
		0% {
			transform: translateY(-25px);
			opacity: 0;
		}

		50% {
			transform: translateY(-25px);
			opacity: 0;
		}

		100% {
			transform: translateY(0);
			opacity: 1;
		}
	}

	@keyframes slideIn_2 {
		0% {
			transform: translateY(-60px);
			opacity: 0;
		}

		100% {
			transform: translateY(0);
			opacity: 1;
		}
	}

	@keyframes growWidth_1 {
		from {
			width: 0;
		}

		to {
			width: 100%;
			/* 这里可以根据需要设置分割线的正常宽度 */
		}
	}
</style>

<style scoped>
	.main {
		background-image: url(./img/main-background.jpg);
		background-size: cover;
	}

	.container {
		height: 100vh;
		display: grid;
		user-select: none;
		grid-template-columns: 0.7fr 2.3fr 1fr 1fr 1fr 1fr 0.7fr;
		grid-template-rows: 0.3fr 0.7fr 1.4fr 2.3fr 0.3fr;
		grid-auto-columns: 1fr;
		gap: 0px 0px;
		grid-auto-flow: row;
		grid-template-areas:
			"LEFT . . . . . ."
			"LEFT TITLE . . . SOLAR ."
			"LEFT DES-MAIN DES-MAIN . . . ."
			"LEFT START START START START START ."
			"LEFT . . . . . .";
	}

	.TITLE {
		display: grid;
		grid-template-columns: 1.4fr 0.4fr;
		grid-template-rows: 0.6fr 1.4fr 1fr;
		gap: 0px 0px;
		grid-auto-flow: row;
		grid-template-areas:
			". ."
			"TITLE-MAIN ."
			". .";
		grid-area: TITLE;
	}

	.TITLE-MAIN {
		grid-area: TITLE-MAIN;
		font-size: 6vh;
		font-weight: bold;
		letter-spacing: 3vh;
		font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
		color: aliceblue;
		animation: slideIn_2 1s ease-out forwards;
	}

	.DES-MAIN {
		grid-area: DES-MAIN;
		font-weight: bold;
		font-size: 4rem;
		font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
	}

	.START {
		scale: 0.8;
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
		grid-template-rows: 1.7fr 1fr 0.3fr;
		gap: 0px 0px;
		grid-auto-flow: row;
		grid-template-areas:
			". . ."
			". START-BUTTON ."
			". . .";
		grid-area: START;
	}

	.START-BUTTON {
		grid-area: START-BUTTON;
	}

	.SOLAR {
		grid-area: SOLAR;
	}

	.LEFT {
		grid-area: LEFT;
	}
</style>

<style>
	.home span {
		position: relative;
		z-index: 6;
		text-decoration: none;
		position: absolute;
		transform: translate(-50%, -50%) scale(1);
		font-size: 3rem;
		color: #fff;
		pointer-events: none;
	}

	.home a {
		text-decoration: none;
		position: absolute;
		transform: translate(-50%, -50%) scale(1);
		font-size: 3rem;
		color: #fff;
		background-color: #c934df;
		border-radius: 10rem;
		padding: 1.5rem 4rem 2rem;
		transform-style: preserve-3d;
		transition: all 0.6s ease;
	}

	.home a span {
		position: relative;
		opacity: 0;
	}

	.home a:after {
		content: "";
		background: linear-gradient(56deg, #61dafbbd 0%, #d6cbf697 46%, #f20570b6 100%);
		width: 80%;
		height: 20%;
		position: absolute;
		bottom: -4px;
		left: 10%;
		opacity: 0;
		filter: blur(15px);
		border-radius: 10rem;
		transform: translateZ(-1px);
		transition: opacity 0.6s ease;
	}

	.home a:before {
		content: "";
		background: linear-gradient(56deg, #61dafb81 0%, #d6cbf685 46%, #f2057079 100%);
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		opacity: 0;
		border-radius: 10rem;
		transition: opacity 0.4s ease;
	}

	.home a img {
		display: none;
		position: absolute;
		top: -10px;
		right: -10px;
		width: 50px;
		height: auto;
		opacity: 0;
		transition: opacity 0.6s ease;
	}

	.home a:hover {
		transition: all 1.3s ease;
		transform: translate(-50%, -50%) scale(1.2);
		transform-origin: center;
	}

	.home a:hover:after {
		opacity: 1;
		transition: opacity 0.6s ease;
	}

	.home a:hover:before {
		opacity: 1;
		transition: opacity 0.4s ease;
	}

	.home a:hover img {
		display: block;
		opacity: 1;
	}
</style>