<template>
  <div v-if="reallyon">
    <el-col v-if="isMobile">
      <el-menu :default-active="activeIndex" :ellipsis="false" mode="horizontal" @select="handleSelect"
        background-color="#9AC4F3" text-color="#ffffff" active-text-color="#000000">
        <el-menu-item style="width: 14vw">
          <img style="height: 100%; border-radius: 0.7vh;" src="../img/39d8ab2cdbdf992a72a06d1807b0e29.png" alt="logo" />
        </el-menu-item>
        <el-menu-item index="/App_Home_Pc" style="width: 10vw">主页</el-menu-item>
        <el-menu-item index="/repertory" style="width: 10vw">索引</el-menu-item>
      </el-menu>
    </el-col>
    <el-col v-else>
      <el-menu :default-active="activeIndex" :ellipsis="false" mode="horizontal" @select="handleSelect"
        background-color="#9AC4F3" text-color="#ffffff" active-text-color="#000000">
        <el-menu-item>
          <img style="height: 100%; border-radius: 0.7vh;" src="../img/39d8ab2cdbdf992a72a06d1807b0e29.png" alt="logo" />
        </el-menu-item>
        <el-menu-item index="/App_Home_Mob">主页</el-menu-item>
        <el-menu-item index="/repertory">索引</el-menu-item>
      </el-menu>
    </el-col>
    <div style="margin-top: 7vh; height: 93vh;">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
// import Lenis from 'lenis'

// const lenis = new Lenis();
// function raf(time) {
//   lenis.raf(time);
//   requestAnimationFrame(raf);
// }
// requestAnimationFrame(raf);

import { ElLoading } from 'element-plus';

export default {
  name: 'App',
  data() {
    return {
      activeIndex: '/App_Home_Pc',
      isMobile: false,
      reallyon: false
    };
  },
  created() {
    if (!this._isMobile()) {
      this.$router.push('/App_Home_Pc');
      this.isMobile = true;
    } else {
      this.$router.push('/App_Home_Mob');
      this.isMobile = false;
    }
    this.startLoading();
    this.stopLoading();
  },
  methods: {
    handleSelect(key) {
      this.activeIndex = key;
      this.$router.push(key);
    },
    _isMobile() {
      let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      return flag
    },
    startLoading() {
      this.loadingInstance = ElLoading.service({
        fullscreen: true,
        lock: true,
        text: 'Loading...',
        background: 'rgba(200, 200, 160, 1)',
        customClass: 'custom-loading'
      });
    },
    stopLoading() {
      setTimeout(() => {
        this.loadingInstance.close();
        this.reallyon = true;
      }, 800);
    },
  }
}
</script>

<style>
html,
body {
  overflow-x: hidden;
  height: 100vh;
  width: 100vw;
  margin: 0;
  padding: 0;
  background-color: rgba(242, 242, 242, 0.904);
}

.el-menu--horizontal>.el-menu-item {
  font-weight: bold;
  letter-spacing: 0.2vw;
}

.el-menu--horizontal.el-menu {
  top: 0%;
  width: 100%;
  position: fixed;
  z-index: 10;
  height: 7vh;
}
</style>