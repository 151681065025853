<template>
    <div v-if="loaded" class="content">
        <div class="container">
            <p class="title">{{ this.rm }}</p>
            <hr class="separator" />
            <div class="websites">
                <div class="website" v-for="(item, index) of articles_websites_draw" :key="index"
                    @click="navigateTo(item.aimUrl)">
                    <img :src="item.image" alt="Website Image" class="website-image" />
                    <div class="website-description">
                        <h2>{{ item.name }}</h2>
                        <p>{{ item.description }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div v-else>
        <el-skeleton animated>
            <template #template>
                <div class="container">
                    <div class="title">
                        <el-skeleton-item variant="p" style="width: 80%" />
                    </div>
                    <hr class="separator" />
                    <div class="websites">
                        <div class="website" v-for="(index) of articles_websites_draw" :key="index">
                            <div class="website-image">
                                <el-skeleton-item variant="image" style="width: 85px; height: 85px" />
                            </div>
                            <div class="website-description">
                                <h2><el-skeleton-item variant="text" style="width: 100px" /></h2>
                                <p><el-skeleton :rows="0" style="width: 140px;"/></p>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </el-skeleton>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    props: {
        id: {
            type: Number,
            required: true,
        },
        rm: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            articles_websites_draw: [],
            loaded: false
        };
    },
    methods: {
        navigateTo(url) {
            window.open(url, '_blank');
        },
        async get_all_websites_with_id() {
            try {
                const response = await axios.get(`https://api.aieyes.com.cn/api/websites/tag/all`, {
                    params: { id: this.id },
                });
                this.articles_websites_draw = response.data.map(item => ({
                    name: item.title,
                    description: item.description,
                    aimUrl: item.aimUrl,
                    image: item.imageUrl
                }));
                setTimeout(() => {
                    this.loaded = true;
                }, 500);
            } catch (error) {
                console.error('Error fetching article content:', error);
                this.articleContent = '';
            }
        },
    },
    mounted() {
        this.get_all_websites_with_id();
    },
};
</script>

<style scoped>
.container {
    background-color: white;
    width: 85%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
    border-radius: 2vh;
    padding: 5px;
}

.title {
    margin-bottom: 5px;
    font-size: 22px;
    letter-spacing: 9px;
    font-weight: bold;
    text-align: left;
    margin-left: 40px;
    color: cornflowerblue;
}

.separator {
    border: 0;
    height: 1px;
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0));
    margin-bottom: 20px;
}

.websites {
    display: flex;
    flex-wrap: wrap;
    column-gap: calc(3%);
    row-gap: 7px;
}

.website {
    width: calc(30%);
    display: flex;
    margin-bottom: 5px;
    /* margin-right: auto; */
    transition: box-shadow 0.1s ease, transform 0.5s ease;
    /* 设置过渡效果 */
    box-shadow: none;
    /* 初始没有阴影 */
    transform: translateY(0);
    /* 初始没有平移 */
}

.website:hover {
    background-color: rgba(105, 105, 105, 0.1);
    border-radius: 8px;
    box-shadow: 10px 10px 15px rgba(0, 0, 0, 0.2);
    /* 添加右下角阴影 */
    transform: translateY(-10px);
    transform: translateX(15px);
    /* 向上平移10px */
    cursor: pointer;
    scale: 1.05;
}

.website:hover h2 {
    color: #db25a5bc;
    /* 将颜色设置为红色 */
}

.website:hover .website-description {
    white-space: normal;
}

.website-image {
    scale: 0.8;
    width: 85px;
    height: 85px;
    border-radius: 50%;
    margin-right: 10px;
}

.website-description {
    word-break: break-word;
    overflow-wrap: break-word;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.website-description p {
    font-size: 16px;
    font-weight: bold;
}

h2 {
    margin: 0 0 1px 0;
}

p {
    margin: 0;
}
</style>

<style>
.el-skeleton__p.is-first {
    width: 100%;
}
</style>