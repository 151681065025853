<template>
    <div v-if="Content" class="content">
        <div class="container">
            <hr class="separator" />
            <div class="websites">
                <div class="website" v-for="(item, index) of articles_websites_draw" :key="index"
                    @click="navigateTo(item.aimUrl)">
                    <img :src="item.image" alt="Website Image" class="website-image" />
                    <div class="website-description">
                        <h2>{{ item.name }}</h2>
                        <p>{{ item.description }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div v-else>
        <p>文章不存在或已删除</p>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    props: {
        id: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            Content: '加载中',
            articles_websites_draw: []
        };
    },
    methods: {
        navigateTo(url) {
            window.open(url, '_blank');
        },
        async get_all_websites_with_id() {
            try {
                const response = await axios.get(`https://api.aieyes.com.cn/api/websites/tag/all`, {
                    params: { id: this.id },
                });
                this.articles_websites_draw = response.data.map(item => ({
                    name: item.title,
                    description: item.description,
                    aimUrl: item.aimUrl,
                    image: item.imageUrl
                }));
            } catch (error) {
                console.error('Error fetching article content:', error);
                this.articleContent = '';
            }
        },
    },
    mounted() {
        this.get_all_websites_with_id();
    },
};
</script>

<style scoped>
.container {
    background-color: white;
    width: 85%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
    border-radius: 2vh;
    padding: 5px;
}

.title {
    margin-bottom: 5px;
    font-size: 18px;
    letter-spacing: 9px;
    font-weight: bold;
    text-align: left;
    margin-left: 40px;
    color: cornflowerblue;
}

.separator {
    border: 0;
    height: 1px;
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0));
    margin-bottom: 20px;
}

.websites {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.website {
    width: calc(99% - 10px);
    display: flex;
    margin-bottom: 5px;
}

.website:hover {
    background-color: rgba(105, 105, 105, 0.288);
    border-radius: 8px;
}

.website-image {
    scale: 0.8;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    margin-right: 0px;
}

.website-description {
    word-break: break-word;
    overflow-wrap: break-word;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.website-description p {
    font-size: 12px;
    font-weight: bold;
}

h2 {
    margin: 0 0 1px 0;
}

p {
    margin: 0;
}
</style>