<template>
    <router-view></router-view>
</template>

<script>

export default {
    name: 'App_root',
    data() {
        return {
        };
    },
    methods: {
        MoveToHome() {
            this.homeview = true;
            this.$router.push("/App_Home_Main");
        },
    }
};
</script>