<template>
    <el-scrollbar class="scrollbar">
        <el-tabs v-model="activeName">
            <el-tab-pane :label=item.title :name="item.id" lazy v-for="(item, index) in websites" :key="index">
                <All :id="item.id" />
            </el-tab-pane>
        </el-tabs>
    </el-scrollbar>
</template>

<script>
import axios from 'axios';

import All from './Mob_All.vue'

export default {
    data() {
        return {
            activeName: 1,
            websites: []
        }
    },
    components: {
        All
    },
    mounted() {
        this.fetch_for_websites_tags();

    },
    methods: {
        handleTabChange(tabName) {
            this.activeName = tabName;
        },
        async fetch_for_websites_tags() {
            try {
                const response = await axios.get('https://api.aieyes.com.cn/api/websites/tags');
                this.websites = response.data.map(item => ({
                    id: item.id,
                    title: item.tagtitle
                }));
            } catch (error) {
                console.error('Error fetching total articles:', error);
            }
        }
    },
}
</script>
<style>
.el-tabs--top {
    padding: 2vh;
    background-color: rgba(242, 242, 242, 0.904);
}
</style>